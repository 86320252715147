import feeplanContent from './feeplan-content.yml';
import acpComponentFeeplan from 'components/feeplan';
import { acpFeeplanBaseModule } from 'apps/feeplan/components/feeplan-base';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

var section = ng
  .module('acp.section.feeplan', [
    acpCore.name,
    acpComponentFeeplan.name,
    acpFeeplanBaseModule.name
  ])
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('apps/feeplan', feeplanContent);
    }
  );

import templates from './templates';
templates(section);

export default section;
